import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AppConfig} from "../app.config";
import {Store, StoreShallow} from "../domain/models/store/store";
import {map} from "rxjs/operators";
import {Page, Paginated} from "./models/paginated";
import {ContinuousSetting} from "../domain/models/store/continuous-setting";
import {DiscountCodeSetting} from '../domain/models/store/discount-code-setting';
import {BundleModeSetting} from "../domain/models/store/bundle-mode-setting";
import {ProductBehindCounterSetting} from "../domain/models/store/product-behind-counter";
import {ProductBrowseOnlySetting} from '../domain/models/store/product-browse-only-setting';
import {ProductSearchSetting} from '../domain/models/store/product-search-setting';
import {GateSetting} from '../domain/models/store/gate-setting';

@Injectable({
  providedIn: 'root'
})
export class StoreProvider {

  constructor(private http: HttpClient) {
  }

  getStores(page?: Page): Observable<Paginated<StoreShallow>> {
    return this.http.get<StoreShallow[]>(`${AppConfig.getApiUrl()}/stores`, {
      headers: {'auth-key': AppConfig.authKey},
      params: page?.toParams(),
      observe: 'response',
    }).pipe(map(r => Paginated.fromResponse<StoreShallow>(r)));
  }

  getStore(handle: string): Observable<Store> {
    return this.http.get<Store>(`${AppConfig.getApiUrl()}/stores/${handle}`, {
      headers: {'auth-key': AppConfig.authKey}
    }).pipe(map(r => {
      r.fulfillmentOptions.push(...r.storeChain.fulfillmentOptions);
      return r;
    }));
  }

  getChainStores(chainHandle: string) {
    return this.http.get<StoreShallow[]>(`${AppConfig.getApiUrl()}/stores/chain/${chainHandle}`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }

  getGiftReceiptStatus(storeHandle: string) {
    return this.http.get(`${AppConfig.getApiUrl()}/stores/${storeHandle}/gift-receipt-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }

  continuousScanning(chainId: string, storePublicId: string) {
    return this.http.get<ContinuousSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/continuous-scanning`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  gateSetting(chainId: string, storePublicId: string) {
    return this.http.get<GateSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/gate-code-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  productBrowseOnly(chainId: string, storePublicId: string) {
    return this.http.get<ProductBrowseOnlySetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/product-browse-only-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  productSearchEnabled(chainId: string, storePublicId: string) {
    return this.http.get<ProductSearchSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/product-search-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  discountCodeEnabled(chainId: string, storePublicId: string) {
    return this.http.get<DiscountCodeSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/cart-discount-code`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  productBundleModeEnabled(chainId: string, storePublicId: string) {
    return this.http.get<BundleModeSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/product-bundle-mode-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  productBehindCounterEnabled(chainId: string, storePublicId: string) {
    return this.http.get<ProductBehindCounterSetting>(`${AppConfig.getApiUrl()}/settings/${chainId}/product-behind-counter-enabled`, {
      headers: {'auth-key': AppConfig.authKey},
      params: {storePublicId},
    });
  }

  getStoreByNumber(shortNumber: string): Observable<Store> {
    return this.http.get<Store>(
      `${AppConfig.getApiUrl()}/stores/short`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
        params: {shortNumber},
      },
    );
  }
}

