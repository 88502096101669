import {Injectable} from '@angular/core';
import {StoreProvider} from '../transport/store.provider';
import {Store, StoreShallow} from './models/store/store';
import {Page, Paginated} from '../transport/models/paginated';
import {map} from 'rxjs/operators';
import {sortByIndexAscending} from '../utils/sorting.utils';
import {CampaignUtils} from '../utils/campaign.utils';
import {Cache} from '../utils/cache.utils';
import {ContinuousSetting} from './models/store/continuous-setting';
import {GateSetting} from './models/store/gate-setting';
import {ProductBrowseOnlySetting} from './models/store/product-browse-only-setting';
import {ProductSearchSetting} from './models/store/product-search-setting';
import {DiscountCodeSetting} from './models/store/discount-code-setting';
import {ProductBehindCounterSetting} from './models/store/product-behind-counter';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private storeCache = new Cache<Store>();
  private continuousScanningCache = new Cache<ContinuousSetting>();
  private gateSettingCache = new Cache<GateSetting>();
  private productBrowseOnlyCache = new Cache<ProductBrowseOnlySetting>();
  private productSearchEnabledCache = new Cache<ProductSearchSetting>();
  private discountCodeEnabledCache = new Cache<DiscountCodeSetting>();
  private productBundleModeEnabledCache = new Cache<ProductBehindCounterSetting>();
  private productBehindCounterEnabledCache = new Cache<ProductBehindCounterSetting>();
  contextHistoryPath?: string;
  storeId?: string | null;

  constructor(private storeProvider: StoreProvider,
              private storageService: LocalStorageService,
              private router: Router,
  ) {
  }

  getStores(page?: Page): Promise<Paginated<StoreShallow>> {
    return this.storeProvider.getStores(page).toPromise();
  }

  async getStore(storeHandle: string): Promise<Store> {
    const cachedStore = this.storeCache.get(storeHandle);
    if (cachedStore) {
      return cachedStore;
    }
    const remoteStore = await this.storeProvider.getStore(storeHandle)
      .pipe(map(source => {
        source.categories = sortByIndexAscending(source.categories);
        source.campaigns = source.campaigns.filter(CampaignUtils.isValidCampaign);
        return source;
      }))
      .toPromise();

    this.storeCache.set(storeHandle, remoteStore);

    return remoteStore;
  }

  async getStoreByNumber(shortNumber: string): Promise<Store> {
    return this.storeProvider.getStoreByNumber(shortNumber).toPromise();
  }

  async getChainStores(chainHandle: string) {
    return this.storeProvider.getChainStores(chainHandle).toPromise();
  }

  async getGiftReceiptStatus(storeHandle: string) {
    return this.storeProvider.getGiftReceiptStatus(storeHandle).toPromise();
  }

  async continuousScanning(chainId: string, storeId: string) {
    const cachedContinuousScanning = this.continuousScanningCache.get(chainId + storeId);
    if (cachedContinuousScanning) {
      return cachedContinuousScanning;
    }

    const continuousScanning = await this.storeProvider.continuousScanning(chainId, storeId).toPromise();
    this.continuousScanningCache.set(chainId + storeId, continuousScanning);
    return continuousScanning;
  }

  async gateSetting(chainId: string, storeId: string) {
    const cachedGateSetting = this.gateSettingCache.get(chainId + storeId);
    if (cachedGateSetting) {
      return cachedGateSetting;
    }

    const gateSetting = await this.storeProvider.gateSetting(chainId, storeId).toPromise();
    this.gateSettingCache.set(chainId + storeId, gateSetting);
    return gateSetting;
  }

  async productBrowseOnly(chainId: string, storeId: string) {
    const cachedProductBrowseOnly = this.productBrowseOnlyCache.get(chainId + storeId);
    if (cachedProductBrowseOnly) {
      return cachedProductBrowseOnly;
    }

    const productBrowseOnly = await this.storeProvider.productBrowseOnly(chainId, storeId).toPromise();
    this.productBrowseOnlyCache.set(chainId + storeId, productBrowseOnly);
    return productBrowseOnly;
  }

  async productSearchEnabled(chainId: string, storeId: string) {
    const cachedProductSearchEnabled = this.productSearchEnabledCache.get(chainId + storeId);
    if (cachedProductSearchEnabled) {
      return cachedProductSearchEnabled;
    }

    const productSearchEnabled = await this.storeProvider.productSearchEnabled(chainId, storeId).toPromise();
    this.productSearchEnabledCache.set(chainId + storeId, productSearchEnabled);
    return productSearchEnabled;
  }

  async discountCodeEnabled(chainId: string, storeId: string) {
    const cachedDiscountCodeEnabled = this.discountCodeEnabledCache.get(chainId + storeId);
    if (cachedDiscountCodeEnabled) {
      return cachedDiscountCodeEnabled;
    }

    const discountCodeEnabled = await this.storeProvider.discountCodeEnabled(chainId, storeId).toPromise();
    this.discountCodeEnabledCache.set(chainId + storeId, discountCodeEnabled);
    return discountCodeEnabled;
  }

  async productBundleModeEnabled(chainId: string, storeId: string) {
    const cachedProductBundleModeEnabled = this.productBundleModeEnabledCache.get(chainId + storeId);
    if (cachedProductBundleModeEnabled) {
      return cachedProductBundleModeEnabled;
    }

    const productBundleModeEnabled = await this.storeProvider.productBundleModeEnabled(chainId, storeId).toPromise();
    this.productBundleModeEnabledCache.set(chainId + storeId, productBundleModeEnabled);
    return productBundleModeEnabled;
  }

  async productBehindCounterEnabled(chainId: string, storeId: string) {
    const cachedProductBehindCounterEnabled = this.productBehindCounterEnabledCache.get(chainId + storeId);
    if (cachedProductBehindCounterEnabled) {
      return cachedProductBehindCounterEnabled;
    }

    const productBehindCounterEnabled = await this.storeProvider.productBehindCounterEnabled(chainId, storeId).toPromise();
    this.productBehindCounterEnabledCache.set(chainId + storeId, productBehindCounterEnabled);
    return productBehindCounterEnabled;
  }

  setExternalId(storeHandle: string, externalId: string) {
    this.storageService.store('storeExternalId-' + storeHandle, externalId);
  }

  getExternalId(storeHandle: string) {
    return this.storageService.retrieve('storeExternalId-' + storeHandle);
  }

  async preserveContextInUrl() {
    if (this.contextHistoryPath) {
      await this.router.navigate([], {
        queryParams: {
          ctx: this.contextHistoryPath,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }
}
