import {Injectable} from '@angular/core';
import {CustomerProvider} from '../transport/customer.provider';
import {LocalStorageService} from 'ngx-webstorage';
import {CustomerSmsLoginResponse} from '../transport/models/customer/customer-sms-login.response';
import {LoginDialog, LoginDialogResult, LoginDialogState} from '../dialogs/login-dialog/login.dialog';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {decodeJwt} from 'jose';
import {OrderService} from './order.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  public loginDialogRef?: MatDialogRef<LoginDialog, LoginDialogResult> | null;
  public isSignedIn$;
  private isSignedIn = new BehaviorSubject(false);
  private customerId?: string | null;
  private remoteCustomer?: string | null;

  constructor(private customerProvider: CustomerProvider,
              private storageService: LocalStorageService,
              private dialog: MatDialog,
              private orderService: OrderService,
  ) {
    this.isSignedIn$ = this.isSignedIn.asObservable();
    this.isSignedIn.next(Boolean(this.getAndValidateToken(true)));
  }

  async getCustomerId(): Promise<string> {
    // get from memory
    if (this.customerId != null) {
      return Promise.resolve(this.customerId);
    }

    // get from storage
    const customerId = this.storageService.retrieve('customer-id') as string;
    if (customerId != null) {
      this.customerId = customerId;
      return Promise.resolve(customerId);
    }

    // get from remote
    if (this.remoteCustomer == null) {
      this.remoteCustomer = await this.customerProvider.createAnonymousCustomer().toPromise();
      await this.customerAnonymousAuthenticate(this.remoteCustomer);
    }

    this.customerId = this.remoteCustomer;
    this.remoteCustomer = null;
    this.storageService.store('customer-id', this.customerId);

    return this.customerId;
  }

  async customerAnonymousAuthenticate(customerId: string) {
    const response = await this.customerProvider.authenticateAnonymousCustomer(customerId).toPromise();
    await this.login(response.jwt);
  }

  async customerSmsLogin(phoneNumber: string): Promise<CustomerSmsLoginResponse> {
    return this.customerProvider.customerSmsLogin(phoneNumber).toPromise();
  }

  async customerSmsAuthenticate(phoneNumber: string, smsConfirmationCode: string) {
    const response = await this.customerProvider.customerSmsAuthenticate(phoneNumber, smsConfirmationCode).toPromise();
    await this.login(response.jwt);
  }

  async customerGoogleAuthenticate(googleJwt: string) {
    const response = await this.customerProvider.customerGoogleAuthenticate(googleJwt).toPromise();
    await this.login(response.jwt);
  }

  async customerVippsInitialize(context: string) {
    return this.customerProvider.customerVippsInitialize(context).toPromise();
  }

  async getLoginMethods(storeHandle: string) {
    return this.customerProvider.getLoginMethods(storeHandle).toPromise();
  }

  async customerVippsAuthenticate(code: string) {
    const response = await this.customerProvider.customerVippsAuthenticate(code).toPromise();
    await this.login(response.jwt);
  }

  async login(jwt: string) {
    const {CustomerId, Anonymous} = decodeJwt(jwt);

    if (this.isAnonymousUser() && Anonymous == 'False') {
      const lastKnownOrderId = this.orderService.getLastKnownOrder();

      if (lastKnownOrderId != null) {
        const currentCustomerId = await this.getCustomerId();
        await this.orderService.transferOrder(lastKnownOrderId, currentCustomerId, CustomerId as string);
      }
    }

    // Reset all variables first
    this.logout();

    this.storageService.store('customer-id', CustomerId);
    this.storageService.store('jwt-token', jwt);

    this.customerId = CustomerId as string;
    this.isSignedIn.next(Anonymous == 'False');
  }

  logout() {
    if (this.orderService.getLastKnownOrder()) {
      this.orderService.clearLastKnownOrder();
    } else {
      this.orderService.clearMostRecentPurchase();
    }
    this.storageService.clear('customer-id');
    this.storageService.clear('jwt-token');
    this.customerId = null;
    this.isSignedIn.next(false);
  }

  getAndValidateToken(ignoreAnon = false, maxAgeInSeconds?: number): string | false | null {
    const jwt = this.retrieveToken();
    if (!jwt) {
      return null;
    }

    const {exp, iat, Anonymous, aud, iss} = decodeJwt(jwt);
    if (!aud || !iss) {
      // JWT is missing audience and issuer; token is invalid
      return false;
    }

    const notTooOld = maxAgeInSeconds != undefined ? iat && iat + maxAgeInSeconds > Date.now() / 1000 : true;
    // Invalidate token a few seconds before it expires in order to prompt user for re-authentication before it ends
    const notExpired = exp && exp > (Date.now() / 1000) + 5;
    if (!ignoreAnon && Anonymous == 'True' && notExpired && notTooOld) {
      return jwt;
    }

    if (Anonymous == 'False' && notExpired && notTooOld) {
      return jwt;
    }
    // JWT is set, but invalid
    return false;
  }

  retrieveToken() {
    const jwt = this.storageService.retrieve('jwt-token');
    if (!jwt) {
      return null;
    }
    return jwt;
  }

  isAnonymousUser() {
    const jwt = this.retrieveToken();
    if (!jwt) {
      return null;
    }
    const {Anonymous} = decodeJwt(jwt);
    return Anonymous == 'True';
  }

  openLoginDialog(state?: LoginDialogState) {
    if (!this.loginDialogRef) {
      this.loginDialogRef = this.dialog.open(LoginDialog, {data: {state}, maxHeight: '100vh'});
      this.loginDialogRef.afterClosed().subscribe(result => {
        this.loginDialogRef = null;
        if (
          result != LoginDialogResult.Success &&
          state &&
          [LoginDialogState.LoginRequired, LoginDialogState.LoginExpired].includes(state)
        ) {
          this.logout();
        }
      });
    }
    return this.loginDialogRef;
  }
}
